import { Box, ChakraProps, Link } from '@chakra-ui/react'
import NextLink from 'next/link'
import { FC } from 'react'
import { useConnectParams } from 'src/connect/useParams'

export const Footer: FC<{} & ChakraProps> = ({ ...props }) => {
  const { appendParamsToLink } = useConnectParams()
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      height="50px"
      px={6}
      backgroundColor="gray.50"
      {...props}
    >
      <Box color="gray.600">© MICIN,Inc.</Box>
      <Box color="gray.600">
        <NextLink href={appendParamsToLink({ path: '/contact' })}>
          <Link>お問い合わせ</Link>
        </NextLink>
      </Box>
    </Box>
  )
}
