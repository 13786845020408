import { Button, Center, Container, Heading, Link, Text } from '@chakra-ui/react'
import NextLink from 'next/link'
import { FC } from 'react'
import { momoDefaultParams } from 'src/cookieLogin/params'
import { PagePadding } from 'src/layout/PagePadding'
import { PageRoot } from 'src/layout/PageRoot'
import { Footer } from 'src/navigation/Footer'
import { Header } from 'src/navigation/Header'
import ErrorIcon from './ErrorIcon.svg'

export const NotFound: FC<{}> = ({ ...props }) => {
  return (
    <PageRoot>
      <Header />
      <Container maxW="centeredRow">
        <Center mt="60px">
          <ErrorIcon />
        </Center>
        <Heading textAlign="center" fontSize="lg" mt="40px">
          ページが見つかりませんでした
        </Heading>
        <Center my={6}>
          <Text maxWidth="280px">
            お探しのページはURLが間違っているか、削除された可能性があります。
          </Text>
        </Center>
        <Center mt={6}>
          <NextLink href={{ pathname: '/', query: momoDefaultParams().toString() }}>
            <Button as={Link} width="100%" variant="outline" size="lg">
              トップページへ
            </Button>
          </NextLink>
        </Center>
      </Container>
      <PagePadding />
      <Footer />
    </PageRoot>
  )
}
