import { Box, ChakraProps } from '@chakra-ui/react'
import { FC } from 'react'
import { useClientSetting } from 'src/clientSetting/Provider'
import MomoLogo from './momo.svg'

export const Logo: FC<{} & ChakraProps> = ({ ...props }) => {
  const client = useClientSetting()
  const logo = (client.id === 'default' || client.id === 'momo') && <MomoLogo />

  return <Box {...props}>{logo}</Box>
}
